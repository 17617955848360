import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './Reducers';
import { AppProvider, Loader } from '@cedcommerce-integration/ounce';
import './index.css';
import '@cedcommerce-integration/ounce/dist/index.css';
import CookieDisabled from './Templates/CookieDisabled';
import NetworkDisabled from './Templates/NetworkDisabled';
const App = lazy(() => import('Src/App'));
export const StoreDispatcher = React.createContext(store.dispatch);
const container = document.getElementById('root')!;
const root = createRoot(container);
import * as Sentry from '@sentry/react';
const pathname = window.location.pathname;
const pathSegments = pathname.split('/');
const userId = pathSegments[2];
const queryStrings = window.location.search;

const isEnableSentry = (USER_ID: string = '') => {
  if (process.env.REACT_APP_SENTRY !== '1') {
    return false;
  }
  const users = process.env.REACT_APP_SENTRY_USER_ENABLE?.split(',') || [];
  if (users.length === 0) {
    return true;
  }
  return users.includes(USER_ID);
};

isEnableSentry(userId) &&
  !queryStrings.includes('admin_user_token') &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
      process.env.REACT_APP_SENTRY_MODE === 'production' ? 1.0 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppProvider>
        <NetworkDisabled>
          <CookieDisabled>
            <StoreDispatcher.Provider value={store.dispatch}>
              <Suspense fallback={<Loader type='L-1' />}>
                <App />
              </Suspense>
            </StoreDispatcher.Provider>
          </CookieDisabled>
        </NetworkDisabled>
      </AppProvider>
    </Provider>
  </BrowserRouter>
);
